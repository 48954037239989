import styled from "styled-components";

const Hyper = styled.h1`
    font-family: 'Hyper';
    color: white;
    user-select: none;
    margin: 0;
    font-weight: 400;
    font-size: ${props => props.fs || props.fs}
    word-spacing: 2rem;
    padding-top: .5rem;
    cursor: pointer;
`;

Hyper.defaultProps = {
    fs: "30px"
};

export default Hyper;

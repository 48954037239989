import React, { Component } from 'react';
import { Page } from 'hedron';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Async from './common/Async';
import ScrollToTop from './common/ScrollToTop';
import Intercom from './common/Intercom';
import Nav from './Nav';

const Services = () => <Async load={import('./views/Services')} />;
const Ventures = () => <Async load={import('./views/Ventures')} />;
const About = () => <Async load={import('./views/About')} />;
const Payment = () => <Async load={import('./views/PaymentView')} />;

class App extends Component {
	render() {
		return (
			<Router>
				<Page>
					<Route exact path="/" component={About} />

					<Route path="/services" component={Services} />
					<Route path="/ventures" component={Ventures} />

					<Route component={Nav} />
					<Route component={ScrollToTop} />
					<Route component={Intercom} />

					<Route
						exact
						path="/about"
						render={() => <Redirect to="/" />}
					/>
				</Page>
			</Router>
		);
	}
}

export default App;

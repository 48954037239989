import styled from "styled-components";
import React from "react";
import { NavLink } from "react-router-dom";

const StyledNavLink = styled(({ fs, children, ...rest }) => (
    <NavLink {...rest}>{children}</NavLink>
))`
    color: #fff;
    border-bottom: 1px solid rgba(192, 192, 192, 0);
    transition: all 500ms ease;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        border-bottom: 1px solid rgba(192, 192, 192, 1);
    }

    @media only screen and (max-width: 768px) {
        font-size: ${props => props.fs || "14px"};

        &:hover {
            border-bottom: 1px solid rgba(192, 192, 192, 0);
        }
    }

    @media only screen and (max-width: 500px) {
        font-size: ${props => props.fs || "14px"};
    }

`;

StyledNavLink.defaultProps = {
    activeStyle: {
        color: "#2753FD"
    }
};

export default StyledNavLink;

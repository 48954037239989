import styled from "styled-components";

const Nav = styled.nav`
    border-top: solid 1px #1d1d1d;
    background: #0B0B0B;
    height: 8vh;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;

    @media only screen and (max-width: 1100px) {
        border-top: none;
        height: 60px;
        top: 30px;
        bottom: auto;
        border-bottom: solid 1px #1d1d1d;
    }

    @media only screen and (max-width: 500px) {

    }
`;

export default Nav;

import React from "react";

class Intercom extends React.Component {
    componentDidMount() {
        window.Intercom("boot", {
            app_id: "kkafbm1q"
        });
    }
    render() {
        return null;
    }
}

// const intercomeDiv = document.getElementById('intercom-container')
// intercomeDiv.querySelectorAll('.intercom-notification-channels-input')A

// find label and set innerText #intercom-container .intercom-notification-channels-placeholder

// find input and set variable document.querySelector('input[name=intercom-channel-collector]')

export default Intercom;

import React from "react";
import styled from "styled-components";
import { Row, Column, Hidden } from "hedron";
import StyledNav from "./tags/Nav";
import A from "./tags/A";
import NavLink from "./common/NavLink";
import Hyper from "./common/Hyper";

class Nav extends React.Component {
    goHome = () => {
        this.props.history.push("/");
    };
    onContact = () => {
        window.Intercom("show");
    };
    render() {
        const { int } = this.props;

        return (
            <Row>
                <StyledNav>
                    <Row style={{ textAlign: "center" }}>
                        <Column
                            int={int + 1}
                            fluid
                            lg={4}
                            style={{ padding: "0" }}
                        >
                            <Hidden xs sm md>
                                <Column lg={12} fluid>
                                    <Hyper fs="40px" onClick={this.goHome}>
                                        Hyper Labs
                                    </Hyper>
                                </Column>
                            </Hidden>
                        </Column>
                        <Column lg={8} fluid>
                            <StyledRow justifyContent="flex-end">
                                <Column
                                    style={{ padding: "none" }}
                                    int={int * 4}
                                    lg={2}
                                    xs={3}
                                >
                                    <NavLink exact to="/">About</NavLink>
                                </Column>
                                <Column
                                    style={{ padding: "none" }}
                                    int={int * 8}
                                    lg={2}
                                    xs={3}
                                >
                                    <NavLink to="/services">
                                        Services
                                    </NavLink>
                                </Column>
                                <Column
                                    style={{ paddingTop: "none" }}
                                    int={int * 12}
                                    lg={2}
                                    xs={3}
                                >
                                    <NavLink to="/ventures">
                                        Ventures
                                    </NavLink>
                                </Column>
                                <Column
                                    style={{ padding: "none" }}
                                    int={int * 16}
                                    lg={2}
                                    xs={3}
                                >
                                    <A onClick={this.onContact}>
                                        Contact
                                    </A>
                                </Column>
                            </StyledRow>
                        </Column>
                    </Row>
                </StyledNav>

                <Hidden lg>
                    <Column lg={12} fluid key={2}>
                        <MobileNavHeader>
                            <Hyper onClick={this.goHome} fs="30px">
                                Hyper Labs
                            </Hyper>
                        </MobileNavHeader>
                    </Column>
                </Hidden>
            </Row>
        );
    }
}

Nav.defaultProps = {
    int: 10
};

const MobileNavHeader = styled.div`
    border-top: solid 5px #1d1d1d;
    background: #0B0B0B;
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    z-index: 1;
`;

const StyledRow = styled(Row)`
    @media only screen and (max-width: 1100px) {
        padding-top: 7px;
    }
`;

export default Nav;
